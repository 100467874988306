
import React, { Component } from "react";
import cx from 'classnames';
import Styles from '../assets/styles/howItWorksSteps.module.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import newslogos from "../assets/images/newslogos.svg";
import "../assets/styles/broker-hero.css?ver=0.1";
import BrokerFinderSearch from "../components/brokers/brokerFinderSearch";

library.add(faCheck);

class BrokerHero extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // circularAnimation: '',
      // allContents: '', 
    }
  }

  render() {
    var searchData = {};
    searchData.edges = [];
    searchData.edges[0] = {};
    searchData.edges[0].node = {};
    searchData.edges[0].node.elements = {};
    searchData.edges[0].node.elements.simplified = true;
    searchData.edges[0].node.elements.search_textheading = {};
    searchData.edges[0].node.elements.search_placeholder = {};
    searchData.edges[0].node.elements.search_buttontext = {};
    searchData.edges[0].node.elements.search_textheading.value = "Search top rated local brokers";
    searchData.edges[0].node.elements.search_placeholder.value = "Enter postcode or state";
    searchData.edges[0].node.elements.search_buttontext.value = "Find my broker";
    return (
      <section className={"top-banner"}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-md-9"}>
              <div className={"banner-content"}>
                <h1 className={"page-title"}>
                  <span>Searching for a <nobr>better deal?</nobr></span></h1>
                <h2 className={"page-subtitle"}>Find a top-rated independent mortgage broker <nobr>near you.</nobr></h2>
                <BrokerFinderSearch searchKenticoLabel={searchData} />
                {/* <p>Join 100,000+ Australians who have used HFG Marketplace's free service to secure a <nobr>better deal!</nobr></p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
};

export default BrokerHero;
